<template lang="">
    <div style="padding-bottom: 30px;">
        <section class="sectionView clearfix section1">
            <div class="top clearfix">
                <div class="title fl">公司背景</div>
            </div>
            <div class="center clearfix">
                <!-- 历史改革 -->
                <div class="blue-title fl">
                    1、历史沿革
                </div>
                <div class="blue-text fl">
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        <span class="years">
                            1996年-1997年
                        </span>
                        先后组建xxxx集团公司、xxxxx子公司；
                    </p>
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        <span class="years">
                            2018年
                        </span>
                        实行股份制改造；
                    </p>
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        <span class="years">
                            2019年07月29日
                        </span>
                        温州市博弘电力有限公司成立
                    </p>
                </div>
                <!-- 2、所处行业及其产品特点 -->
                <div class="blue-title fl">
                    2、所处行业及其产品特点
                </div>
                <div class="blue-text fl">
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        博弘电力是应急行业的标志性企业
                    </p>
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        博弘电力的主营业务：电力开关、电力设备的研发、生产、销售；自动化设备及系统的设计研发与施工安装；电力工程的设计、咨询、施工、安装、调试、维修服务；货物进出口、技术进出口等
                    </p>
                    <p>
                        <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_titile.png" class="img">
                        &nbsp;
                        电力开关是博弘电力的支柱产品，在从意大利引进的新技术基础上，提高了开关的制造技术，扩大了生产能力，县具有年产12万太的生产能力
                    </p>
                </div>
                <!-- 3、经营业绩 -->
                <div class="blue-title fl">
                    3、经营业绩
                </div>
                <div class="blue-text fl">
                    <p style="padding-left: 35px;">
                        公司的产品销售量超过月均50000个，覆盖4个县29个乡镇50万城乡居民。巴拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦，拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦拉巴拉巴拉南啦啦
                    </p>
                </div>
                <!-- 4、组织机构及人员配置 -->
                <div class="blue-title fl">
                    4、组织机构及人员配置
                </div>
                <div class="fl w100">
                    <div class="half-box fl" style="width: calc((100% - 40px) * 0.5);margin-right: 40px;height: 510px;">
                        <div id="mountNode" style="width: 100%;height: 100%;">

                        </div>
                    </div>
                    <div class="fl" style="width: calc((100% - 40px) * 0.5);margin-top: 16px;">
                        <div class="clearfix w100">
                            <div class="info-box fl">
                                <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_zcyg.png" class="img">
                                <p>
                                    在册员工
                                </p>
                                <p>
                                    <span class="number">1,000</span>人
                                </p>
                            </div>
                            <div class="info-box fl">
                                <img src="../../assets/img/IntelligentDiagnosis/qyjyfx_icon_pjnl.png" class="img">
                                <p>
                                    平均年龄
                                </p>
                                <p>
                                    <span class="number">25</span>岁
                                </p>
                            </div>
                            <div class="info-box fl">
                                <img src="../../assets/img/IntelligentDiagnosis/icon-nan@2x.png" class="img">
                                <p>
                                    男性
                                </p>
                                <p style="font-size: 16px;color: #255ADA;font-weight: 600;">
                                    28.67%
                                </p>
                            </div>
                            <div class="info-box fl">
                                <img src="../../assets/img/IntelligentDiagnosis/icon-nv@2x.png" class="img">
                                <p>
                                    男性
                                </p>
                                <p style="font-size: 16px;color: #F5694D;font-weight: 600;">
                                    28.67%
                                </p>
                            </div>
                        </div>
                        <!-- <Chart class="chart-container" ref="pieChart1" style="height: 192px;margin: 16px 0;"></Chart> -->
                        <v-chart autoresize :options="pieOption" class="chart-container" style="height: 192px;margin: 16px 0;"/>
                        <div class="chart-container clearfix" style="height: 154px;">
                            <div class="huantitle">
                                年龄比例
                            </div>
                            <v-chart autoresize :options="huanOption1" class="gauge fl" />
                            <v-chart autoresize :options="huanOption2" class="gauge fl" />
                            <v-chart autoresize :options="huanOption3" class="gauge fl" />
                            <v-chart autoresize :options="huanOption4" class="gauge fl" />
                            <v-chart autoresize :options="huanOption5" class="gauge fl" />
                            <v-chart autoresize :options="huanOption6" class="gauge fl" />
                            <!-- <Chart class="gauge fl" ref="huan1"></Chart>
                            <Chart class="gauge fl" ref="huan2"></Chart>
                            <Chart class="gauge fl" ref="huan3"></Chart>
                            <Chart class="gauge fl" ref="huan4"></Chart>
                            <Chart class="gauge fl" ref="huan5"></Chart>
                            <Chart class="gauge fl" ref="huan6"></Chart> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="sectionView clearfix section2">
            <div class="top clearfix">
                <div class="title fl">背景分析</div>
            </div>
            <div class="center">
                <div class="w100 clearfix">
                    <div class="half-box fl" style="width: calc((100% - 40px) * 0.5);margin-right: 40px;height: 339px;">
                        <div class="p-title">
                            企业所处环境分析
                            <div class="fr edit">
                                <img src="../../assets/img/IntelligentDiagnosis/icon_edit.png" class="img">
                            </div>
                        </div>
                        <div class="advice">
                            <div class="p-item">
                                <div class="advice-title">
                                    社会环境
                                </div>
                                <p>
                                    处于瓯江口园区
                                </p>
                            </div>
                            <div class="p-item">
                                <div class="advice-title">
                                    政策环境
                                </div>
                                <p>
                                    《产业结构调整指导目录》 <br>
                                    西部大开发 <br>
                                    十二五工业发展规划
                                </p>
                            </div>
                            <div class="p-item">
                                <div class="advice-title">
                                    经济环境
                                </div>
                                <p>
                                    园区内经济总量：<span class="number">2000</span>亿元 <span class="green">23.53%↑</span>
                                </p>
                                <p>
                                    行业经济总产值：<span class="number">3151</span>亿元 <span class="red">23.53%↓</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="half-box fl" style="width: calc((100% - 40px) * 0.5);height: 339px;">
                        <div class="p-title">
                            行业分析
                            <div class="fr edit">
                                <img src="../../assets/img/IntelligentDiagnosis/icon_edit.png" class="img">
                            </div>
                        </div>
                        <div class="advice">
                            <p>
                                公司主要经营供电营业区域内的电力设备销售业务,属于应急行业。目前国内有多个竞争企业，主要有：Xxxxx、xxxxx和xxxxx。
                            </p>
                            <p>
                                XX电力辖广东、广西、XX、贵州、海南五个省区。电力企业属于自然垄断行业，没有引入竞争机制。公司执行XX电网公司的中长期发展战略及XX电网子战略行动计划,战略发展目标是成为服务好、管理好、形象好的国际领先企业。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="sectionView clearfix section3">
            <div class="top clearfix">
                <div class="title fl">近三年公司经营状况分析</div>
            </div>
            <div class="center">
                <el-table :data="tableData1" style="width: 100%;border: 1px solid #ddd;"
                    :header-cell-style="{background:'#F3F3F5'}">
                    <el-table-column fixed prop="column1" label="流动资产" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column2" label="固定资产净值" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column3" label="在建工程" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column4" label="资产总额" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column5" label="流动负债" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column6" label="长期负债" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column7" label="所有者权益" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column8" label="所有者权益2" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column9" label="所有者权益3" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column10" label="所有者权益4" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column11" label="所有者权益5" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="column12" label="所有者权益6" width="150" align="center">
                    </el-table-column>
                </el-table>
                <div class="advice">
                    <div class="advice-title">总结</div>
                    <p class="advice-subtitle"> 1、资产分析</p>
                    <p class="advice-concent"> <span class="blue">流动资产上升</span>；</p>
                    <p class="advice-concent"> 货币资金的余额<span class="blue">变动幅度较大</span>；</p>

                    <p class="advice-subtitle"> 2、非流动资产分析</p>
                    <p class="advice-concent">固定资产<span class="blue">增长缓慢</span>。固定资产<span
                            class="blue">基数大</span>基数大，折旧费占用公司成本总额的<span class="blue">30%</span>，且固定资产及折旧费有<span
                            class="blue">逐步增长</span>的态势。</p>
                    <p class="advice-subtitle"> 3、长短期借款分析</p>
                    <p class="advice-concent">公司<span class="blue">贷款较多，xxx</span>年长期借款、短期借款的合计数为<span
                            class="blue">6589万元</span>。其中，短期借款需及时偿还，公司还本付息压力大，利息沉重。</p>
                    <p class="advice-subtitle"> 4、净资产分析</p>
                    <p class="advice-concent"><span class="blue">净资产大于实收资本，实现资产保值增值，扭亏为盈。</span></p>
                    <p class="advice-subtitle"> 5、损益分析</p>
                    <p class="advice-concent"><span class="blue">净资产大于实收资本，实现资产保值增值，扭亏为盈。</span></p>
                </div>
                <div class="btn btn-primary" style="margin-top: 20px;width: 267px;">
                    绩效评价指标——查看企业综合评估
                </div>
            </div>
        </section>

        <section class="sectionView clearfix section4">
            <div class="top clearfix">
                <div class="title fl">
                    近三年成本费用分析
                </div>
                <div class="fr edit">
                    <img src="../../assets/img/IntelligentDiagnosis/icon_edit.png" class="img">
                </div>
            </div>
            <div class="center clearfix">
                <el-table :data="tableData2" border style="width: 65%;float: left;">
                    <el-table-column prop="column1" label="生产成本" align="center">
                    </el-table-column>
                    <el-table-column prop="column2" label="2019年" align="center">
                    </el-table-column>
                    <el-table-column prop="column3" label="同比增长" align="center">
                    </el-table-column>
                    <el-table-column prop="column4" label="2020年" align="center">
                    </el-table-column>
                    <el-table-column prop="column5" label="同比增长" align="center">
                    </el-table-column>
                    <el-table-column prop="column6" label="2021年" align="center">
                    </el-table-column>
                    <el-table-column prop="column7" label="同比增长" align="center">
                    </el-table-column>
                </el-table>
                <div class="advice fl" style="width: 35%;padding-left: 40px;">
                    <div class="advice-title">总结</div>
                    <p>
                        公司主要经营供电营业区域内的电力设备销售业务,属于应急行业。目前国内有多个竞争企业，主要有：Xxxxx、xxxxx和xxxxx。
                    </p>
                    <p style="margin-top: 40px;">
                        XX电力辖广东、广西、XX、贵州、海南五个省区。电力企业属于自然垄断行业，没有引入竞争机制。公司执行XX电网公司的中长期发展战略及XX电网子战略行动计划,战略发展目标是成为服务好、管理好、形象好的国际领先企业。
                    </p>
                </div>

            </div>
        </section>

        <section class="sectionView clearfix section5">
            <div class="red-bg"></div>
            <div class="top clearfix">
                <div class="title fl">
                    公司面临的关键问题及分析
                </div>
                <div class="fr edit">
                    <img src="../../assets/img/IntelligentDiagnosis/icon_edit.png" class="img">
                </div>
            </div>
            <div class="center">
                <div class="advice">
                    <div class="advice-title2">总结一</div>
                    <div class="advice-subtitle">
                        资产负债率高，资产机构不合理，财务风险较大
                    </div>
                    <p style="margin-top: 10px;">
                        此处展示xxxx详细解释，次段人极数却什点新半完段改京素金二空好没米动说花可思第美花更价张直老该低多实值增较她可往走采较只才形要为步较。领代必问老圆务率已得都局效东即很文亲火色科比出结两至积为调把土权根八影基音线。下县起其引经斯角今由为识石到华气之素务新位流住号格积且低酸低较构阶从他本县始空感号原。济界长习总确民采子级识便将观心务工象法快写门该府主严拉机消积就战今学构能术求切程到十入本身北证厂且飞。种论三得干式制还力厂四进用代为带或始状程干单离七则北光史金江养听往连门利至说步称劳收图好党子且于在际华前例导全类。该省起些也队状建律话而称动单感才数道上类引法区质表关后历众共出联听代通究他长门天书百不各去部办马
                    </p>

                    <div style="height: 24px;"></div>

                    <div class="advice-title2">总结二</div>
                    <div class="advice-subtitle">
                        此处需要企业或者分析人员自行分析，手动填写输入
                    </div>
                    <p style="margin-top: 10px;">
                        此处展示xxxx详细解释，次段人极数却什点新半完段改京素金二空好没米动说花可思第美花更价张直老该低多实值增较她可往走采较只才形要为步较。领代必问老圆务率已得都局效东即很文亲火色科比出结两至积为调把土权根八影基音线。下县起其引经斯角今由为识石到华气之素务新位流住号格积且低酸低较构阶从他本县始空感号原。
                    </p>

                    <div style="height: 24px;"></div>

                    <div class="advice-title2">总结三</div>
                    <div class="advice-subtitle">
                        此处需要企业或者分析人员自行分析，手动填写输入
                    </div>
                    <p style="margin-top: 10px;">
                        此处展示xxxx详细解释，次段人极数却什点新半完段改京素金二空好没米动说花可思第美花更价张直老该低多实值增较她可往走采较只才形要为步较。领代必问老圆务率已得都局效东即很文亲火色科比出结两至积为调把土权根八影基音线。下县起其引经斯角今由为识石到华气之素务新位流住号格积且低酸低较构阶从他本县始空感号原。济界长习总确民采子级识便将观心务工象法快写门该府主严拉机消积就战今学构能术求切程到十入本身北证厂且飞。种论三得干式制还力厂四进用代为带或始状程干单离七则北光史金江养听往连门利至说步称劳收图好党子且于在际华前例导全类。该省起些也队状建律话而称动单感才数道上类引法区质表关后历众共出联听代通究他长门天书百不各去部办马
                    </p>

                    <div style="height: 24px;"></div>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
import G6 from '@antv/g6';
var echarts = require('echarts');
export default {
  data() {
    return {
      tableData1: [
        {
          column1: '2021',
          column2: '3135',
          column3: '1822',
          column4: '1722',
          column5: '8349',
          column6: '3872',
          column7: '8415',
          column8: '1567',
          column9: '6462',
          column10: '1245',
          column11: '6462',
          column12: '1245',
        },
        {
          column1: '2020',
          column2: '3135',
          column3: '1822',
          column4: '1722',
          column5: '8349',
          column6: '3872',
          column7: '8415',
          column8: '1567',
          column9: '6462',
          column10: '1245',
          column11: '6462',
          column12: '1245',
        },
        {
          column1: '2019',
          column2: '3135',
          column3: '1822',
          column4: '1722',
          column5: '8349',
          column6: '3872',
          column7: '8415',
          column8: '1567',
          column9: '6462',
          column10: '1245',
          column11: '6462',
          column12: '1245',
        },
      ],
      tableData2: [
        {
          column1: '供电成本',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '职工薪酬',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '其中工资',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '工挂费',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '折旧费',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '材料费',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '修理费',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '其它费',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
        {
          column1: '财务费用',
          column2: '7727',
          column3: '17.85%',
          column4: '1154',
          column5: '9.98%',
          column6: '9661',
          column7: '21.28%',
        },
      ],
    };
  },
  methods: {
    initRelationChart() {
      var realWidth = document.querySelector('#mountNode').offsetWidth;
      var realHeight = document.querySelector('#mountNode').offsetHeight;
      var data = {
        nodes: [
          {
            id: '1',
            label: '董事会',
          },
          {
            id: '2',
            label: '总经理',
          },
          {
            id: '3',
            label: '副总经理',
          },
          {
            id: '4',
            label: '浙江分公司',
          },
          {
            id: '5',
            label: '上海分公司',
          },
          {
            id: '6',
            label: '市场部',
          },
          {
            id: '7',
            // label: fittingString('项目一组11111', 30, 14),
            label: '项\n目\n一\n组',
            isLeaf: true,
          },
          {
            id: '8',
            // label: fittingString('项目二组222222', 30, 14),
            label: '项\n目\n二\n组',
            isLeaf: true,
          },
        ],
        edges: [
          {
            source: '1',
            target: '2',
          },
          {
            source: '2',
            target: '3',
          },
          {
            source: '3',
            target: '4',
          },
          {
            source: '3',
            target: '5',
          },
          {
            source: '3',
            target: '6',
          },
          {
            source: '4',
            target: '7',
          },
          {
            source: '4',
            target: '8',
          },
        ],
      };

      var graph = new G6.Graph({
        container: 'mountNode',
        width: realWidth,
        height: realHeight,
        fitView: true,
        fitCenter: true,
        fitViewPadding: [0, 0, 170, 0],
        linkCenter: true,
        modes: {
          default: ['drag-node', 'drag-canvas'],
        },
        defaultNode: {
          type: 'rect',
          color: 'steelblue',
          style: {
            fill: '#EFF7FF',
            stroke: '#7C8186',
            lineWidth: 1,
          },
          labelCfg: {
            autoRotate: true,
          },
        },
        defaultEdge: {
          type: 'cubic-vertical',
        },
      });
      var nodes = data.nodes;
      var edges = data.edges;
      var nodeMap = new Map();
      var branchNodes = [];
      var branchEdges = [];
      var leafGroups = {};
      var branchNodesMap = new Map();
      var leafGroupOffset = 100;
      // separate the leaves and branch nodes
      nodes.forEach(function (node, i) {
        nodeMap.set(node.id, node);
        if (node.isLeaf) {
          node.shape = 'rect';
          node.size = [30, 100];
          edges.forEach(function (edge) {
            if (edge.source === node.id) {
              node.parent = edge.target;
            } else if (edge.target === node.id) {
              node.parent = edge.source;
            }
          });
          if (!leafGroups[node.parent]) leafGroups[node.parent] = [];
          leafGroups[node.parent].push(node);
        } else {
          branchNodes.push(node);
          branchNodesMap.set(node.id, node);
        }
      });
      edges.forEach(function (edge) {
        if (branchNodesMap.get(edge.source) !== undefined && branchNodesMap.get(edge.target) !== undefined) {
          branchEdges.push(edge);
        }
      });
      // layout the nodes with isLeaf === false by dagre
      var dagreLayout = new G6.Layout['dagre']({
        nodesep: 50, // 可选
        ranksep: 10, // 可选
      });
      dagreLayout.init({
        nodes: branchNodes,
        edges: branchEdges,
      });
      dagreLayout.execute();

      //layout the leaves with isLeaf === true by force
      var forceLayoutsMap = new Map();
      Object.keys(leafGroups).forEach(function (parentId) {
        var lg = leafGroups[parentId];
        var parent = branchNodesMap.get(parentId);
        var center = [parent.x, parent.y + leafGroupOffset];
        var forceLayout = new G6.Layout['force']({
          center: center,
          linkDistance: 0,
          nodeStrength: 30,
          collideStrength: 0.7,
          alphaDecay: 0.01,
          preventOverlap: true,
          tick: function tick() {
            graph.refreshPositions();
          },
        });
        forceLayout.init({
          nodes: lg,
          edges: [],
        });
        forceLayout.execute();
        forceLayoutsMap.set(parentId, forceLayout);
      });

      graph.positionsAnimate();

      graph.data(data);
      graph.render();

      function refreshDragedNodePosition(e) {
        var model = e.item.get('model');
        model.fx = e.x;
        model.fy = e.y;
      }
      graph.on('node:dragstart', function (e) {
        var model = e.item.get('model');
        if (model.isLeaf) {
          var layoutMethod = forceLayoutsMap.get(model.parent);
          layoutMethod.ticking = false;
          layoutMethod.forceSimulation.stop();
          layoutMethod.execute();
          refreshDragedNodePosition(e);
        }
      });
      graph.on('node:drag', function (e) {
        var model = e.item.get('model');
        if (model.isLeaf) {
          refreshDragedNodePosition(e);
        }
      });
      graph.on('node:dragend', function (e) {
        var model = e.item.get('model');
        if (model.isLeaf) {
          e.item.get('model').fx = null;
          e.item.get('model').fy = null;
        }
      });
    },
    
  },
  created() {},
  mounted() {
    // this.initCharts();
    // this.initPieChart();
    this.initRelationChart();
  },
  computed: {
    pieOption(){
      let pieData1 = [
        { value: 115, name: '本科学历', percent: '20.22' },
        { value: 259, name: '大专学历', percent: '28.23' },
        { value: 179, name: '中专学历', percent: '21.25' },
        { value: 376, name: '高中及以下', percent: '31.23' },
      ];
      let colors = ['#255ADA', '#14C53E', '#ED7A2C', '#E22132'];
      let legendData = [];
      for (var j = 0; j < pieData1.length; j++) {
        var data = {
          name: pieData1[j].name,
          icon: 'circle',
          textStyle: {
            fontSize: 14,
            color: '#666',
          },
        };
        legendData.push(data);
      }
      let objData = array2obj(pieData1, 'name');
      function array2obj(array, key) {
        var resObj = {};
        for (var i = 0; i < array.length; i++) {
          resObj[array[i][key]] = array[i];
        }
        return resObj;
      }
      return {
          backgroundColor: '#EFF7FF',
          title: {
            text: '学历比例',
            x: 'left',
            y: 'top',
          },
          legend: {
            orient: 'vertical',
            top: 'center',
            left: '50%',
            itemGap: 15,
            data: legendData,
            formatter: function (name) {
              return `{title|${name}} {value|${objData[name].value}}{value|人} {value|${objData[name].percent}} {value|%}`;
            },
            textStyle: {
              rich: {
                value: {
                  color: '#000',
                  fontSize: 14,
                },
                unit: {
                  color: '#82baff',
                  fontSize: 14,
                  fontWeight: 600,
                },
                percent: {
                  color: '#82baff',
                  fontSize: 14,
                  fontWeight: 600,
                },
              },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          color: colors,
          series: [
            {
              name: '学历比例',
              type: 'pie',
              radius: '75%',
              center: ['30%', '50%'],
              label: {
                normal: {
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  borderWidth: 2,
                  borderColor: '#EFF7FF',
                },
              },
              data: pieData1,
            },
          ],
        }
    },
    huanOption1() {
      return {
        title: [
          {
            text: '29岁以下',
            bottom: 0,
            x: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: 14,
              color: '#666',
            },
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: 'value',
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: 'category',
        },
        //圆环位置和大小
        polar: {
          center: ['50%', '50%'],
          radius: '170%',
        },
        series: [
          {
            type: 'bar',
            data: [
              {
                //上层圆环，显示数据
                value: 75,
                itemStyle: {
                  color: {
                    //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1, //从左到右 0-1
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#1F9DFF',
                      },
                      {
                        offset: 1,
                        color: '#255ADA',
                      },
                    ],
                  },
                },
              },
            ],
            barGap: '-100%', //柱间距离,上下两层圆环重合
            coordinateSystem: 'polar',
            roundCap: true, //顶端圆角
            z: 2, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: 'bar',
            data: [
              {
                value: 100,
                itemStyle: {
                  color: '#E9ECF0',
                },
              },
            ],
            barGap: '-100%',
            coordinateSystem: 'polar',
            roundCap: true,
            z: 1,
          },
          //仪表盘
          {
            name: '29岁以下',
            type: 'gauge',
            startAngle: 180, //起始角度，同极坐标
            endAngle: 0, //终止角度，同极坐标
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              offsetCenter: [-20, -10],
              color: '#000',
              fontSize: 14,
              fontWeight: 500,
              rich: {
                a: {
                  fontWeight: 'normal',
                  fontSize: 16,
                  color: '#000',
                  padding: [0, 0, 0, 0],
                },
              },
            },
            detail: {
              show: true,
              //formatter: '1\n性格指数',
              formatter: ['{name|9.78%}', '{value}人'].join('\n'),
              rich: {
                name: {
                  fontSize: 14,
                  lineHeight: 25,
                  color: '#000',
                  fontWeight: 'bolder',
                },
              },
              color: '#000',
              fontSize: 14,
              fontWeight: 'bolder',
              offsetCenter: [-0, 8],
            },
            data: [
              {
                value: 75,
              },
            ],
          },
        ],
      };
    },
    huanOption2() {
      return {
        title: [
          {
            text: '30-34岁',
            bottom: 0,
            x: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: 14,
              color: '#666',
            },
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: 'value',
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: 'category',
        },
        //圆环位置和大小
        polar: {
          center: ['50%', '50%'],
          radius: '170%',
        },
        series: [
          {
            type: 'bar',
            data: [
              {
                //上层圆环，显示数据
                value: 75,
                itemStyle: {
                  color: {
                    //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1, //从左到右 0-1
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#B4EC51',
                      },
                      {
                        offset: 1,
                        color: '#64CF39',
                      },
                    ],
                  },
                },
              },
            ],
            barGap: '-100%', //柱间距离,上下两层圆环重合
            coordinateSystem: 'polar',
            roundCap: true, //顶端圆角
            z: 2, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: 'bar',
            data: [
              {
                value: 100,
                itemStyle: {
                  color: '#E9ECF0',
                },
              },
            ],
            barGap: '-100%',
            coordinateSystem: 'polar',
            roundCap: true,
            z: 1,
          },
          //仪表盘
          {
            name: '29岁以下',
            type: 'gauge',
            startAngle: 180, //起始角度，同极坐标
            endAngle: 0, //终止角度，同极坐标
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              offsetCenter: [-20, -10],
              color: '#000',
              fontSize: 14,
              fontWeight: 500,
              rich: {
                a: {
                  fontWeight: 'normal',
                  fontSize: 16,
                  color: '#000',
                  padding: [0, 0, 0, 0],
                },
              },
            },
            detail: {
              show: true,
              //formatter: '1\n性格指数',
              formatter: ['{name|12.67%}', '{value}人'].join('\n'),
              rich: {
                name: {
                  fontSize: 14,
                  lineHeight: 25,
                  color: '#000',
                  fontWeight: 'bolder',
                },
              },
              color: '#000',
              fontSize: 14,
              fontWeight: 'bolder',
              offsetCenter: [-0, 8],
            },
            data: [
              {
                value: 75,
              },
            ],
          },
        ],
      };
    },
    huanOption3() {
      return {
          title: [
            {
              text: '35-39岁',
              bottom: 0,
              x: 'center',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14,
                color: '#666',
              },
            },
          ],
          angleAxis: {
            show: false,
            max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
            type: 'value',
            startAngle: 180, //极坐标初始角度
            splitLine: {
              show: false,
            },
          },
          barMaxWidth: 10, //圆环宽度
          radiusAxis: {
            show: false,
            type: 'category',
          },
          //圆环位置和大小
          polar: {
            center: ['50%', '50%'],
            radius: '170%',
          },
          series: [
            {
              type: 'bar',
              data: [
                {
                  //上层圆环，显示数据
                  value: 75,
                  itemStyle: {
                    color: {
                      //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 1, //从左到右 0-1
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#5CC6FB',
                        },
                        {
                          offset: 1,
                          color: '#8B9FE7',
                        },
                      ],
                    },
                  },
                },
              ],
              barGap: '-100%', //柱间距离,上下两层圆环重合
              coordinateSystem: 'polar',
              roundCap: true, //顶端圆角
              z: 2, //圆环层级，同zindex
            },
            {
              //下层圆环，显示最大值
              type: 'bar',
              data: [
                {
                  value: 100,
                  itemStyle: {
                    color: '#E9ECF0',
                  },
                },
              ],
              barGap: '-100%',
              coordinateSystem: 'polar',
              roundCap: true,
              z: 1,
            },
            //仪表盘
            {
              name: '29岁以下',
              type: 'gauge',
              startAngle: 180, //起始角度，同极坐标
              endAngle: 0, //终止角度，同极坐标
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLabel: {
                show: false,
              },
              pointer: {
                show: false,
              },
              title: {
                offsetCenter: [-20, -10],
                color: '#000',
                fontSize: 14,
                fontWeight: 500,
                rich: {
                  a: {
                    fontWeight: 'normal',
                    fontSize: 16,
                    color: '#000',
                    padding: [0, 0, 0, 0],
                  },
                },
              },
              detail: {
                show: true,
                //formatter: '1\n性格指数',
                formatter: ['{name|12.67%}', '{value}人'].join('\n'),
                rich: {
                  name: {
                    fontSize: 14,
                    lineHeight: 25,
                    color: '#000',
                    fontWeight: 'bolder',
                  },
                },
                color: '#000',
                fontSize: 14,
                fontWeight: 'bolder',
                offsetCenter: [-0, 8],
              },
              data: [
                {
                  value: 75,
                },
              ],
            },
          ],
        };
    },
    huanOption4() {
      return {
          title: [
            {
              text: '40-44岁',
              bottom: 0,
              x: 'center',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14,
                color: '#666',
              },
            },
          ],
          angleAxis: {
            show: false,
            max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
            type: 'value',
            startAngle: 180, //极坐标初始角度
            splitLine: {
              show: false,
            },
          },
          barMaxWidth: 10, //圆环宽度
          radiusAxis: {
            show: false,
            type: 'category',
          },
          //圆环位置和大小
          polar: {
            center: ['50%', '50%'],
            radius: '170%',
          },
          series: [
            {
              type: 'bar',
              data: [
                {
                  //上层圆环，显示数据
                  value: 75,
                  itemStyle: {
                    color: {
                      //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 1, //从左到右 0-1
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#FFA300',
                        },
                        {
                          offset: 1,
                          color: '#E09E28',
                        },
                      ],
                    },
                  },
                },
              ],
              barGap: '-100%', //柱间距离,上下两层圆环重合
              coordinateSystem: 'polar',
              roundCap: true, //顶端圆角
              z: 2, //圆环层级，同zindex
            },
            {
              //下层圆环，显示最大值
              type: 'bar',
              data: [
                {
                  value: 100,
                  itemStyle: {
                    color: '#E9ECF0',
                  },
                },
              ],
              barGap: '-100%',
              coordinateSystem: 'polar',
              roundCap: true,
              z: 1,
            },
            //仪表盘
            {
              name: '29岁以下',
              type: 'gauge',
              startAngle: 180, //起始角度，同极坐标
              endAngle: 0, //终止角度，同极坐标
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLabel: {
                show: false,
              },
              pointer: {
                show: false,
              },
              title: {
                offsetCenter: [-20, -10],
                color: '#000',
                fontSize: 14,
                fontWeight: 500,
                rich: {
                  a: {
                    fontWeight: 'normal',
                    fontSize: 16,
                    color: '#000',
                    padding: [0, 0, 0, 0],
                  },
                },
              },
              detail: {
                show: true,
                //formatter: '1\n性格指数',
                formatter: ['{name|12.67%}', '{value}人'].join('\n'),
                rich: {
                  name: {
                    fontSize: 14,
                    lineHeight: 25,
                    color: '#000',
                    fontWeight: 'bolder',
                  },
                },
                color: '#000',
                fontSize: 14,
                fontWeight: 'bolder',
                offsetCenter: [-0, 8],
              },
              data: [
                {
                  value: 75,
                },
              ],
            },
          ],
        };
    },
    huanOption5() {
      return {
          title: [
            {
              text: '50-54岁',
              bottom: 0,
              x: 'center',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14,
                color: '#666',
              },
            },
          ],
          angleAxis: {
            show: false,
            max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
            type: 'value',
            startAngle: 180, //极坐标初始角度
            splitLine: {
              show: false,
            },
          },
          barMaxWidth: 10, //圆环宽度
          radiusAxis: {
            show: false,
            type: 'category',
          },
          //圆环位置和大小
          polar: {
            center: ['50%', '50%'],
            radius: '170%',
          },
          series: [
            {
              type: 'bar',
              data: [
                {
                  //上层圆环，显示数据
                  value: 75,
                  itemStyle: {
                    color: {
                      //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 1, //从左到右 0-1
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#00E5E4',
                        },
                        {
                          offset: 1,
                          color: '#198EE7',
                        },
                      ],
                    },
                  },
                },
              ],
              barGap: '-100%', //柱间距离,上下两层圆环重合
              coordinateSystem: 'polar',
              roundCap: true, //顶端圆角
              z: 2, //圆环层级，同zindex
            },
            {
              //下层圆环，显示最大值
              type: 'bar',
              data: [
                {
                  value: 100,
                  itemStyle: {
                    color: '#E9ECF0',
                  },
                },
              ],
              barGap: '-100%',
              coordinateSystem: 'polar',
              roundCap: true,
              z: 1,
            },
            //仪表盘
            {
              name: '29岁以下',
              type: 'gauge',
              startAngle: 180, //起始角度，同极坐标
              endAngle: 0, //终止角度，同极坐标
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLabel: {
                show: false,
              },
              pointer: {
                show: false,
              },
              title: {
                offsetCenter: [-20, -10],
                color: '#000',
                fontSize: 14,
                fontWeight: 500,
                rich: {
                  a: {
                    fontWeight: 'normal',
                    fontSize: 16,
                    color: '#000',
                    padding: [0, 0, 0, 0],
                  },
                },
              },
              detail: {
                show: true,
                //formatter: '1\n性格指数',
                formatter: ['{name|12.67%}', '{value}人'].join('\n'),
                rich: {
                  name: {
                    fontSize: 14,
                    lineHeight: 25,
                    color: '#000',
                    fontWeight: 'bolder',
                  },
                },
                color: '#000',
                fontSize: 14,
                fontWeight: 'bolder',
                offsetCenter: [-0, 8],
              },
              data: [
                {
                  value: 75,
                },
              ],
            },
          ],
        };
    },
    huanOption6() {
      return {
          title: [
            {
              text: '55岁以上',
              bottom: 0,
              x: 'center',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14,
                color: '#666',
              },
            },
          ],
          angleAxis: {
            show: false,
            max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
            type: 'value',
            startAngle: 180, //极坐标初始角度
            splitLine: {
              show: false,
            },
          },
          barMaxWidth: 10, //圆环宽度
          radiusAxis: {
            show: false,
            type: 'category',
          },
          //圆环位置和大小
          polar: {
            center: ['50%', '50%'],
            radius: '170%',
          },
          series: [
            {
              type: 'bar',
              data: [
                {
                  //上层圆环，显示数据
                  value: 75,
                  itemStyle: {
                    color: {
                      //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 1, //从左到右 0-1
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#FF6FA6',
                        },
                        {
                          offset: 1,
                          color: '#D31DB9',
                        },
                      ],
                    },
                  },
                },
              ],
              barGap: '-100%', //柱间距离,上下两层圆环重合
              coordinateSystem: 'polar',
              roundCap: true, //顶端圆角
              z: 2, //圆环层级，同zindex
            },
            {
              //下层圆环，显示最大值
              type: 'bar',
              data: [
                {
                  value: 100,
                  itemStyle: {
                    color: '#E9ECF0',
                  },
                },
              ],
              barGap: '-100%',
              coordinateSystem: 'polar',
              roundCap: true,
              z: 1,
            },
            //仪表盘
            {
              name: '29岁以下',
              type: 'gauge',
              startAngle: 180, //起始角度，同极坐标
              endAngle: 0, //终止角度，同极坐标
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLabel: {
                show: false,
              },
              pointer: {
                show: false,
              },
              title: {
                offsetCenter: [-20, -10],
                color: '#000',
                fontSize: 14,
                fontWeight: 500,
                rich: {
                  a: {
                    fontWeight: 'normal',
                    fontSize: 16,
                    color: '#000',
                    padding: [0, 0, 0, 0],
                  },
                },
              },
              detail: {
                show: true,
                //formatter: '1\n性格指数',
                formatter: ['{name|12.67%}', '{value}人'].join('\n'),
                rich: {
                  name: {
                    fontSize: 14,
                    lineHeight: 25,
                    color: '#000',
                    fontWeight: 'bolder',
                  },
                },
                color: '#000',
                fontSize: 14,
                fontWeight: 'bolder',
                offsetCenter: [-0, 8],
              },
              data: [
                {
                  value: 75,
                },
              ],
            },
          ],
        };
    },
  },
};
</script>
<style scoped>
.w100 {
  width: 100%;
}

.sectionView {
  padding: 16px 20px;
  background-color: #fff;
  margin: 16px 0;
}

.sectionView .top {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 16px;
  padding-bottom: 18px;
  z-index: 3;
  position: relative;
}

.sectionView .title {
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
  position: relative;
  padding-left: 14px;
}

.sectionView .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4393f8;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 0;
}

.section5 .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #e22132;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 0;
}

.sectionView .p-title {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  position: relative;
  padding-left: 6px;
  margin-bottom: 16px;
  font-weight: 600;
}

.sectionView .p-title:before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 14px;
  background-color: #0e459c;
  position: absolute;
  top: 3px;
  left: 0;
}

.p-item {
  margin-bottom: 20px;
}

.section1 .blue-title {
  background-image: linear-gradient(90deg, #94c1ee 0%, #e9f4ff 100%);
  border-radius: 8px 8px 8px 0;
  font-size: 14px;
  color: #0e459c;
  padding: 9px 15px;
  line-height: 20px;
}

.section1 .blue-text {
  width: 100%;
  background-image: linear-gradient(270deg, #ffffff 0%, #eff7ff 100%);
  font-size: 14px;
  color: #333;
  padding: 16px;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 30px;
}

.section1 .years {
  font-size: 14px;
  color: #255ada;
  font-weight: 600;
}

.section1 .img {
  position: relative;
  top: 2px;
}

.half-box {
  background: #eff7ff;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.person rect {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1px;
}

/* .person {
            font: 14px sans-serif;
            cursor: pointer;
        } */

.section1 .info-box {
  width: calc((100% - 72px) / 4);
  padding: 15px;
  text-align: center;
  font-size: 14px;
  color: #666666;
  border-radius: 4px;
  margin-right: 16px;
}

.section1 .info-box:nth-child(-n + 2) {
  background-image: linear-gradient(0deg, #eff7ff 0%, #bddeff 100%);
}

.section1 .info-box:nth-child(2) {
  margin-right: 40px;
}

.section1 .info-box:nth-child(3) {
  border: 1px solid #255ada;
}

.section1 .info-box:nth-child(4) {
  border: 1px solid #f5694d;
  margin-right: 0;
}

.section1 .info-box img {
  width: 48px;
  height: auto;
}

.section1 .info-box p {
  line-height: 24px;
}

.number {
  font-family: Helvetica;
  font-size: 24px;
  color: #000000;
}

.section1 .chart-container {
  background: #eff7ff;
  padding: 16px;
}

.gauge {
  width: 16.6%;
  height: 110px;
}

.huantitle {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  font-weight: 600;
}

.table1 {
  border: 1px solid #dddddd;
}

.table1 th {
  background-color: #f3f3f5 !important;
  font-weight: 600;
  color: #666;
}

.section4 table,
.section4 table th {
  text-align: center;
}

.section4 >>> .el-table th:nth-child(1) {
  background: #f3f3f5 !important;
}

.section4 >>> .el-table th:nth-child(n + 1):nth-child(n + 2) {
  background: #edf6fe !important;
}

.section4 >>> .el-table th:nth-child(n + 3):nth-child(n + 4) {
  background: #effeed !important;
}

.section4 >>> .el-table th:nth-child(n + 5):nth-child(n + 6) {
  background: #fef8ed !important;
}

.green {
  color: #1ac643;
  font-weight: 600;
}

.blue {
  color: #2e61db;
  font-weight: 600;
}

.red {
  color: #e22132;
  font-weight: 600;
}

.advice {
  font-size: 14px;
  margin-top: 20px;
}

.advice-title {
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.advice-title2 {
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.advice-subtitle {
  margin-top: 10px;
  font-weight: 600;
  color: #333;
}

.advice-title::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  height: 4px;
  width: 100%;
  opacity: 0.4;
  background: #255ada;
}

.advice-title2::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  height: 4px;
  width: 100%;
  opacity: 0.4;
  background: #e22132;
}

.advice .advice-concent {
  text-indent: 22px;
}

.section5 {
  position: relative;
}

.red-bg {
  width: 164px;
  height: 71px;
  background-image: linear-gradient(270deg, #ffffff 0%, #ffc8cd 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.edit {
  cursor: pointer;
}

.btn {
  padding: 10px 20px;
  background: #024a8f;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>