<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">智能诊断</el-breadcrumb-item>
      <el-breadcrumb-item>企业评估</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="fl-c-c year-box">
      <label>统计年份</label>
      <el-select v-model="year">
        <el-option label="2021" value="2021"></el-option>
        <el-option label="2020" value="2020"></el-option>
        <el-option label="2019" value="2019"></el-option>
        <el-option label="2018" value="2018"></el-option>
        <el-option label="2017" value="2017"></el-option>
      </el-select>
    </div>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="企业综合评估" name="evaluate"></el-tab-pane>
      <el-tab-pane label="企业能耗诊断" name="energy"></el-tab-pane>
      <el-tab-pane label="企业经营诊断" name="manage"></el-tab-pane>
    </el-tabs>

    <div class="content-box">
      <evaluate v-if="activeName == 'evaluate'" />
      <energy v-if="activeName == 'energy'" />
      <manage v-if="activeName == 'manage'" />
    </div>
  </div>
</template>

<script>
import evaluate from './evaluate.vue';
import energy from './energy.vue';
import manage from './manage.vue';
export default {
  name: 'IntelligentDiagnosis',
  data() {
    return {
      activeName: 'evaluate',
      year:'2019'
    };
  },
  methods: {},
  created() {},
  components: {
    evaluate,
    energy,
    manage,
  },
};
</script>

<style lang='scss' scoped>
.wrapper {
  position: relative;
}
.year-box {
  position: absolute;
  top: 70px;
  right: 83px;
  label {
    color: #666666;
    margin-right: 12px;
  }
}
.el-breadcrumb {
  margin: 10px auto 26px;
  line-height: 40px;
}
/deep/ .el-tabs--border-card {
  box-shadow: none;
}

/deep/ .el-tabs--border-card > .el-tabs__header {
  border-bottom: none;
  background: #e5e7ec;
  font-family: PingFangSC-Semibold;
}

/deep/ .el-tabs--border-card {
  border: none;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #0e459c;
  border-top: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #333;
  margin-top: 0;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #333;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item,
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child {
  margin-left: 0;
}

/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>