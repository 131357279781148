<template lang="">
    <div style="padding-bottom: 30px;">
        <div class="dataOverview page-top">
            <!-- <div class="title">
                企业名称
            </div> -->
            <!-- <div class="line"></div> -->
            企业综合评价：<span class="grade youxiu" style="margin-right:66px;">优秀</span>
            企业类型：工业
        </div>
        <div class="dataOverview clearfix">
            <div class="view-item fl">
                <div class="title fl-c-b">
                    规模经济实力<span class="grade youxiu">优秀</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <div class="chart-comtainer clearfix">
                        <!-- <div class="chart-box fl" id="pie1">
                        </div>
                        <div class="chart-box fl" id="pie2">
                        </div> -->
                   <!-- <Chart ref="pie1"  class="chart-box fl" ></Chart> -->
                    <v-chart autoresize :options="option1" class="chart-box fl" />
                    <v-chart autoresize :options="option2" class="chart-box fl" />
                   <!-- <Chart ref="pie2"  class="chart-box fl" ></Chart> -->

                    </div>
                    <div class="zongjie">
                        两项指标是企业经营规模、试产竞争能力的表现。当前企业在行业内：<br>
                        市场占有率为<span class="highlight1" style='font-weight: 600;'>32.23%</span>，超过了<span class="highlight1" style='font-weight: 600;'>95%</span>的同行企业。<br>
                        利税占有率为<span class="highlight1" style='font-weight: 600;'>25.35%</span>，超过了<span class="highlight1" style='font-weight: 600;'>94%</span>的同行企业。<br>
                    </div>
                </div>
            </div>
            <div class="view-item fl">
                <div class="title fl-c-b">
                    投入产出能力<span class="grade lianghao">良好</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <h4>
                        全员劳动生产率：<b>40000元/人</b>
                        <span class="fr">

                        </span>
                        <span class="fr" style="color: #14C53E;">25.23% <img
                                src="../../assets/img/IntelligentDiagnosis/up.png" alt=""></span>
                    </h4>
                    <p>
                        工业增加值：2000万元
                    </p>
                    <p>
                        全部职工平均人数：500人
                    </p>

                    <div class="zongjie">
                        根据指标反映，企业生产效率和人均产出水平<span class="highlight1">较高</span>，超出行业内<span
                            class="highlight1">80%</span>的企业。
                    </div>
                    <div class="line"></div>

                    <h4>
                        成本费用利率：<b>35.35%</b>
                        <span class="fr" style="color: #E22132;">2.23% <img
                                src="../../assets/img/IntelligentDiagnosis/down.png" alt=""></span>
                    </h4>
                    <p>
                        利润总额：1000万元
                    </p>
                    <p>
                        成本费用：1000万元
                    </p>
                    <div class="wall">
                        <p>
                            <span class="fl" style="margin-left: -40px;">其中</span>
                            产品销售成本：250万元
                        </p>
                        <p>
                            销售费用：250万元
                        </p>
                        <p>
                            管理费用：250万元
                        </p>
                        <p>
                            财务费用：250万元
                        </p>
                    </div>

                    <div class="zongjie">
                        根据指标反映，企业投入产出效率<span class="highlight1">普通</span>，超出行业内<span class="highlight1">80%</span>的企业。
                    </div>
                </div>
            </div>
            <div class="view-item fl">
                <div class="title fl-c-b">
                    运营能力<span class="grade putong">普通</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <h4>
                        流动资产周转率：
                    </h4>
                    <div class="publicity clearfix">
                        <div class="merchant">
                            2.33&nbsp;次 <br> <span class="fr" style="color: #14C53E;">5.62% <img
                                    src="../../assets/img/IntelligentDiagnosis/up.png" alt=""></span>
                        </div>
                        <div class="sign">
                            =
                        </div>
                        <div class="divided">
                            产品销售收入 <br> 2330万元
                        </div>
                        <div class="sign">
                            /
                        </div>
                        <div class="divisor">
                            流动资产平均余额 <br> 1000万元
                        </div>
                    </div>
                    <div class="zongjie">
                        当前指标反映出，企业流动资产的周转速度<span class="highlight1">较快</span>，营运状况<span class="highlight1">良好</span>。
                    </div>
                    <div class="line"></div>
                    <h4>
                        产品销售率：
                    </h4>
                    <p style="font-size:14px;color:#333;font-weight:bold;">
                        65%
                    </p>
                    <p>
                        <span style="color: #E22132;">5.62% <img src="../../assets/img/IntelligentDiagnosis/down.png"
                                alt=""></span>
                    </p>
                    <div class="jindutiao">
                        <div class="jindu-title">
                            工业总产值
                        </div>
                        <div class="jindu-container">
                            <div class="jindu-inner">工业销售产值</div>
                        </div>
                    </div>
                    <div class="zongjie">
                        指标反映，企业的产销衔接<span class="highlight1">紧密</span>，市场占有率<span class="highlight1">较大</span>。
                    </div>
                </div>
            </div>
            <div class="view-item fl">
                <div class="title fl-c-b">
                    盈利能力<span class="grade jiaocha">较差</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <h4>
                        总资产报酬率：<b>32.69%</b>
                        <span class="fr" style="color: #14C53E;">5.62% <img
                                src="../../assets/img/IntelligentDiagnosis/up.png" alt=""></span>
                    </h4>
                    <p>
                        利润总额：2000万元
                    </p>
                    <p>
                        税收总额：1000万元
                    </p>
                    <p>
                        利息支出：1000万元
                    </p>
                    <p>
                        平均资产总额：1000万元
                    </p>
                    <div class="zongjie">
                        此指标是评价和考核企业能力的核心指标，当前企业的报酬率<span class="highlight1">较好</span>，超过了行业内<span
                            class="highlight1">35%</span>的企业，说明企业盈利能力<span class="highlight1">较好</span>。
                    </div>
                    <div class="line"></div>

                    <h4>
                        净资产收益率：<b>32.69%</b>
                        <span class="fr" style="color: #14C53E;">5.62% <img
                                src="../../assets/img/IntelligentDiagnosis/up.png" alt=""></span>
                    </h4>
                    <p>
                        利润总额：2000万元
                    </p>
                    <p>
                        税收总额：1000万元
                    </p>
                    <p>
                        利息支出：1000万元
                    </p>
                    <p>
                        平均资产总额：1000万元
                    </p>
                    <div class="zongjie">
                        指标反映出，企业出资者向企业投入的全部资本金的获利能力<span class="highlight1">一般</span> 。
                    </div>
                </div>
            </div>
            <div class="view-item fl">
                <div class="title fl-c-b">
                    偿债能力<span class="grade lianghao">良好</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <h4>
                        资产负债率：<b>32.69%</b>
                        <span class="fr">

                        </span>
                        <span class="fr" style="color: #14C53E;">5.62% <img
                                src="../../assets/img/IntelligentDiagnosis/up.png" alt=""></span>
                    </h4>
                    <p>
                        期末负债总额：2000万元
                    </p>
                    <p>
                        期末资产总额：1000万元
                    </p>
                    <div class="line"></div>

                    <h4>
                        营运资金比率：3：1
                    </h4>
                    <p>
                        期末流动资产：2000万元
                    </p>
                    <p>
                        期末流动负债：1000万元
                    </p>
                    <p>
                        期末流动资产余额：1000万元
                    </p>
                    <div class="zongjie">
                        该企业的总资产中，有<span class="highlight1">35.35%</span>的资产由债务形成的，处于<span
                            class="highlight1">良性标准</span>。
                    </div>
                    <div class="zongjie">
                        但营运资金比率较高，超过<span class="highlight1">2:1</span>，说明该企业偿还负债的能力较好。
                    </div>
                </div>
            </div>
            <div class="view-item fl">
                <div class="title fl-c-b">
                    发展能力<span class="grade lianghao">良好</span>
                </div>
                <div class="line"></div>
                <div class="center">
                    <h4>
                        资本保值增值率：<b>32.69%</b>
                        <span class="fr">

                        </span>
                        <span class="fr" style="color: #E22132;">5.62% <img
                                src="../../assets/img/IntelligentDiagnosis/down.png" alt=""></span>
                    </h4>
                    <p>
                        =期末所有者权益÷期初所有者权益x 100%
                    </p>
                    <!-- Table -->
                    <el-table :data="tableData1" border style="width: 100%;" align="center">
                        <el-table-column prop="column1" label="所有者权益" align="center">
                        </el-table-column>
                        <el-table-column prop="column2" label="期初" align="center">
                        </el-table-column>
                        <el-table-column prop="column3" label="期末" align="center">
                        </el-table-column>
                    </el-table>

                    <div class="zongjie">
                        资本保值增值率<span class="highlight1">大于100%</span>，说明企业<span
                            class="highlight1">有经济效益，资本在原有基础上实现了增值。</span>

                    </div>
                    <div class="line"></div>

                    <h4>
                        营运资金比率：<b>32.69%</b>
                        <span class="fr" style="color: #E22132;">5.62% <img
                                src="../../assets/img/IntelligentDiagnosis/down.png" alt=""></span>
                    </h4>
                    <p>
                        工业增加值：2000万元
                    </p>
                    <p>
                        平均资产总额：1000万元
                    </p>

                    <div class="zongjie">
                        增加值超过。
                    </div>
                </div>
            </div>

            <div class="bottom-left fl">
                <div class="view-item fl">
                    <div class="title fl-c-b">
                        排污处理<span class="grade lianghao">良好</span>
                    </div>
                    <div class="line"></div>
                    <div class="center">
                        <div class="boxs clearfix">
                            <div class="box-item fl" style="margin-right: 10px;">
                                <p>主要污染物排放量</p>
                                <p class="number">32.69%</p>
                            </div>
                            <div class="box-item fl">
                                <p>污染物达标排放率</p>
                                <p class="number">32.69%</p>
                            </div>
                        </div>
                        <div class="zongjie">
                            指标反映出，企业的污染物排放<span class="highlight1">较多</span>，<span class="highlight1">超过</span>同行业平均水平。
                            达标排放率<span class="highlight1">较高，超过行业内76%的企业，治理效果较好</span>。
                        </div>

                    </div>
                </div>
                <div class="view-item fl">
                    <div class="title fl-c-b">
                        能耗评价<span class="grade lianghao">良好</span>
                    </div>
                    <div class="line"></div>
                    <div class="center">
                        <!-- Table -->
                        <el-table :data="tableData2" border style="width: 100%;" align="center">
                            <el-table-column prop="column1" label="" align="center">
                            </el-table-column>
                            <el-table-column prop="column2" label="煤气" align="center">
                            </el-table-column>
                            <el-table-column prop="column3" label="用水量" align="center">
                            </el-table-column>
                            <el-table-column prop="column4" label="耗电量" align="center">
                            </el-table-column>
                        </el-table>
                        <div class="zongjie">
                            指标反映出，企业能耗<span class="highlight1">超出</span>了行业平均水平，说明<span
                                class="highlight1">存在能源消耗的情况</span>。
                        </div>

                    </div>
                </div>

            </div>
            <div class="bottom-right fl">
                <div class="view-item fl">
                    <div class="title fl-c-b">
                        达产评价<span class="grade lianghao">良好</span>
                    </div>
                    <div class="line"></div>
                    <div class="center">
                        <!-- Table -->
                        <el-table :data="tableData3" border style="width: 100%;">
                            <el-table-column prop="column1" label="达产标准项" align="center">
                            </el-table-column>
                            <el-table-column prop="column2" label="标准值" align="center">
                            </el-table-column>
                            <el-table-column prop="column3" label="当前值" align="center">
                            </el-table-column>
                            <el-table-column prop="column4" label="达标状态" align="center">
                            </el-table-column>
                            <el-table-column prop="column5" label="差额" align="center">
                            </el-table-column>
                        </el-table>
                        <div class="zongjie">
                            该企业的达产标准中有 2 项未达标，分别是<span class="highlight1">研发经费支出/主营业务收入</span>和<span
                                class="highlight1">项目容积率</span>，目前已完成标准额的<span class="highlight1">99%</span>。
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import G6 from '@antv/g6';
var echarts = require('echarts');
export default {
  data() {
    return {
      tableData1: [
        {
          column1: '实收资本',
          column2: '1000万元',
          column3: '2000万元',
        },
        {
          column1: '资本公积',
          column2: '1000万元',
          column3: '2000万元',
        },
        {
          column1: '盈余公积',
          column2: '1000万元',
          column3: '2000万元',
        },
        {
          column1: '未分配利润',
          column2: '1000万元',
          column3: '2000万元',
        },
      ],
      tableData2: [
        {
          column1: '企业',
          column2: '500m³',
          column3: '500m³',
          column4: '5000kw·h',
        },
        {
          column1: '行业平均',
          column2: '500m³',
          column3: '500m³',
          column4: '5000kw·h',
        },
      ],
      tableData3: [
        {
          column1: '项目总投资额',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '固定资产投资强度',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '亩均年产值',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '亩均税收',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '单位能耗增加值',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '单位排放增加值',
          column2: '15000万元',
          column3: '15001万元',
          column4: '已达标',
          column5: '1万元',
        },
        {
          column1: '研发经费支出/主营业务收入',
          column2: '15000万元',
          column3: '15001万元',
          column4: '未达标',
          column5: '1万元',
        },
        {
          column1: '项目容积率',
          column2: '15000万元',
          column3: '15001万元',
          column4: '未达标',
          column5: '1万元',
        },
      ],
    };
  },
  methods: {},
  created() {},
  mounted() {},

  computed: {
    option1() {
      return {
        color: ['#255ADA', '#14C53E'],
        title: {
          text: '市场占有率',
          x: 'center',
          y: 'bottom',
          textStyle: {
            color: '#666',
          },
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{a}：<br/>{b} : {c}({d}%)',
        },

        legend: {
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [0, '50%'],
            center: ['65%', '50%'],
            data: [
              {
                // 蓝色
                name: '',
                value: 100 - 30,
              },
              {
                // 绿色
                name: '',
                value: 30 * 1,
                label: {
                  normal: {
                    formatter: '{c|{c}%}  \n  {b|5.62% ↑}', //图形外文字上下显示
                    padding: [0, 0], //文字和图的边距
                    rich: {
                      a: {
                        color: '#333',
                        fontSize: 14,
                        lineHeight: 20,
                      },
                      b: {
                        //name 文字样式
                        fontSize: 14,
                        color: '#14C53E',
                        // backgroundColor: {
                        //   image: '../../static/assets/img/digitalTransformation/up.png',
                        //   width: '8px',
                        //   height: '10px'
                        // }
                      },
                      c: {
                        //value 文字样式
                        fontSize: 14,
                        lineHeight: 30,
                        fontWeight: 700,
                        color: '#333',
                        align: 'center',
                      },
                    },
                  },
                },
              },
            ],
            itemStyle: {
              normal: {
                label: {
                  padding: [0, 0],
                },
                labelLine: {
                  show: false,
                },
              },
            },
          },
        ],
      };
    },
    option2() {
      return {
        color: ['#255ADA', '#14C53E'],
        title: {
          text: '利税占有率',
          x: 'center',
          y: 'bottom',
          textStyle: {
            color: '#666',
          },
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{a}：<br/>{b} : {c}({d}%)',
        },

        legend: {
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [0, '50%'],
            center: ['60%', '50%'],
            data: [
              {
                // 蓝色
                name: '',
                value: 100 - 10,
              },
              {
                // 绿色
                name: '',
                value: 10 * 1,
                label: {
                  normal: {
                    formatter: '{c|{c}%}  \n  {b|5.62% ↑}', //图形外文字上下显示
                    padding: [0, 0], //文字和图的边距
                    rich: {
                      a: {
                        color: '#333',
                        fontSize: 14,
                        lineHeight: 20,
                      },
                      b: {
                        //name 文字样式
                        fontSize: 14,
                        color: '#14C53E',
                        // backgroundColor: {
                        //   image: '../../static/assets/img/digitalTransformation/up.png',
                        //   width: '8px',
                        //   height: '10px'
                        // }
                      },
                      c: {
                        //value 文字样式
                        fontSize: 14,
                        lineHeight: 30,
                        fontWeight: 700,
                        color: '#333',
                        align: 'center',
                      },
                    },
                  },
                },
              },
            ],
            itemStyle: {
              normal: {
                label: {
                  padding: [0, 0],
                },
                labelLine: {
                  show: false,
                },
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
.grade.youxiu {
  color: #255ada;
}

.grade.lianghao {
  color: #14c53e;
}

.grade.putong {
  color: #ee6723;
}

.grade.jiaocha {
  color: #e22132;
}

.highlight1 {
  color: #1b4fa1;
  font-size: 16px;
}

.dataOverview {
  margin: 16px 0;
}

.page-top {
  height: 76px;
  font-weight: 500;
  color: #000000;
  line-height: 76px;
  font-size: 26px;
  padding: 0 16px;
  background-color: #fff;
  margin-top: 0;
}

.line {
  height: 1px;
  background-color: #ddd;
  margin-top: 15px;
  margin-bottom: 15px;
}

.view-item {
  height: 600px;
  margin-right: 18px;
  width: calc((100% - 36px) / 3);
  background-color: #fff;
  padding: 16px;
  margin-bottom: 16px;
  overflow-y: auto;
}

.view-item:nth-child(3n) {
  margin-right: 0;
}

.view-item .title {
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
  position: relative;
  padding-left: 14px;
}

.view-item .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4393f8;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 0;
}

.view-item h4 {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-bottom: 16px;
}

.view-item p {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}

.view-item .center .zongjie {
  margin-top: 16px;
}

.view-item .center .wall {
  padding: 8px;
  padding-left: 48px;
  background: #eff7ff;
  margin-top: 12px;
}

.chart-comtainer > .chart-box {
  height: 270px;
  width: 50%;
}

.bottom-left {
  height: 500px;
  width: calc((100% - 36px) / 3);
  margin-right: 18px;
}

.bottom-left .view-item {
  width: 100%;
  height: 292px;
}

.bottom-left .view-item .boxs .box-item {
  width: calc((100% - 10px) / 2);
  background: #eff7ff;
  padding: 12px 24px;
  text-align: center;
  font-size: 14px !important;
}

.bottom-left .view-item .boxs .box-item .number {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.bottom-right {
  width: calc((100% - 18px) / 3 * 2);
}

.bottom-right .view-item {
  width: 100%;
}

.font-red {
  color: #e22132;
}

.publicity {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.publicity div {
  float: left;
}

.publicity .sign {
  color: #666;
  font-size: 28px;
  margin: 0 10px;
}

.jindu-container {
  width: 100%;
  height: 48px;
  background: rgba(37, 90, 218, 0.2);
  border-radius: 24px;
}

.jindu-title {
  text-align: center;
  color: #255ada;
  margin-top: 16px;
  margin-bottom: 8px;
}

.jindu-inner {
  width: 90%;
  height: 48px;
  line-height: 48px;
  color: white;
  border-radius: 24px;
  background-color: #245ada;
  padding-left: 28px;
}

>>> .el-table th {
  background: #f3f3f5;
  color: #666;
}

>>> .el-table td,
>>> .el-table th {
  padding: 10px 0;
}

.bottom-right >>> .el-table__row:nth-last-child(-n + 2) {
  color: #e22132;
}
</style>