<template lang="">
    <div style="padding-bottom: 30px;">
        <div class="result">
            诊断结果 <span class="highlight">较差，亟待改进</span>
        </div>
        <div class="card-list clearfix">
            <div class="card-item fl">
                <p>
                    占地面积
                </p>
                <p>
                    <span class="number">
                        2,000,000
                    </span>
                    m³
                </p>
            </div>
            <div class="card-item fl">
                <p>
                    建筑面积
                </p>
                <p>
                    <span class="number">
                        300,000
                    </span>
                    m³
                </p>
            </div>
            <div class="card-item fl">
                <p>
                    总投资
                </p>
                <p>
                    <span class="number">
                        1.5
                    </span>
                    亿元
                </p>
            </div>
            <div class="card-item fl">
                <p>
                    员工数
                </p>
                <p>
                    <span class="number">
                        4,000
                    </span>
                    人
                </p>
            </div>
        </div>
        <section class="sectionView clearfix section1">
            <div class="top">
                <div class="title">主要产品</div>
            </div>
            <div class="center clearfix" style="margin-bottom: 20px;">
                <div class="product-item fl">
                    蓄电池
                </div>
                <div class="product-item fl">
                    PVC人造革
                </div>
                <div class="product-item fl">
                    PVC地板胶
                </div>
                <div class="product-item fl">
                    PVC薄膜
                </div>
            </div>

        </section>
        <section class="sectionView clearfix">
            <div class="top">
                <div class="title">生产工艺流程</div>
            </div>
            <div class="flow">
                <div class="flow-item">
                    <div class="flow-title">
                        蓄电池生产工艺图
                    </div>
                    <div class="flow-pic">
                        <img src="../../assets/img/IntelligentDiagnosis/znzd_gylc_img1.jpg" alt="">
                    </div>
                </div>
                <div class="flow-item">
                    <div class="flow-title">
                        蓄电池生产工艺图
                    </div>
                    <div class="flow-pic">
                        <img src="../../assets/img/IntelligentDiagnosis/znzd_gylc_img2.jpg" alt="">
                    </div>
                </div>
                <div class="flow-item">
                    <div class="flow-title">
                        PVC人造革生产工艺图
                    </div>
                    <div class="flow-pic">
                        <img src="../../assets/img/IntelligentDiagnosis/znzd_gylc_img3.jpg" alt="">
                    </div>
                </div>
            </div>
        </section>
        <section class="sectionView clearfix section4">
            <div class="top clearfix">
                <div class="title fl">
                    主要能耗设备
                </div>

            </div>
            <div class="center">
                <el-table :data="tableData1" border style="width: 100%;" align="center">
                    <el-table-column prop="area" label="场所" align="center">
                    </el-table-column>
                    <el-table-column prop="device" label="设备" align="center">
                    </el-table-column>
                    <el-table-column prop="model" label="厂家/型号" align="center">
                    </el-table-column>
                    <el-table-column prop="number" label="设备数量" align="center">
                    </el-table-column>
                    <el-table-column prop="power" label="功率（kw/台）" align="center">
                    </el-table-column>
                </el-table>
            </div>
        </section>
        <!-- 当年能源使用情况诊断 -->
        <section class="sectionView clearfix section4">
            <div class="top clearfix">
                <div class="title fl">
                    当年能源使用情况诊断
                </div>

            </div>
            <div class="center">
                <el-table :data="tableData2" border style="width: 100%;" align="center">
                    <el-table-column prop="type" label="能源类型" align="center">
                    </el-table-column>
                    <el-table-column prop="use" label="使用量" align="center">
                    </el-table-column>
                    <el-table-column prop="conversion" label="折标系数" align="center">
                    </el-table-column>
                    <el-table-column prop="standard" label="标准煤" align="center">
                    </el-table-column>
                    <el-table-column prop="average" label="行业平均水平" align="center">
                    </el-table-column>
                    <el-table-column prop="contrast" label="行业对比" align="center">
                    </el-table-column>
                </el-table>
                <div class="advice">
                    <div class="advice-title">总结</div>
                    <p>
                        经分析，公司耗能与行业平均水平相比，耗电量高于行业平均水平，煤气量低于行业平均水平。

                    </p>
                    <p>
                        换算为标准煤统计，总体消耗高于行业平均水平，还需要继续执行节能措施。
                    </p>
                </div>
                <div class="advice">
                    <div class="advice-title2">总结一</div>
                    <div class="advice-subtitle">
                        更换节电设备，随时关电
                    </div>
                </div>
            </div>
        </section>

        <!-- 能源管理制度诊断 -->
        <section class="sectionView clearfix section4">
            <div class="top clearfix">
                <div class="title fl">
                    能源管理制度诊断
                </div>

            </div>
            <div class="center">
                <div class="w100 clearfix">
                    <div class="relation-box fl" id="relation-chart1" style="margin-right: 6%;">
                    </div>
                    <div class="relation-box fl" id="relation-chart2">
                    </div>
                </div>
                <div class="advice">
                    <div class="advice-title">总结</div>
                    <p>
                        经诊断，该公司建立并实施的制度，<span class="blue">满足</span>园区规定的节能制度。
                    </p>
                    <p>
                        经调查，该公司的制度和规定尚未得到有效实施。
                    </p>
                </div>
                <div class="advice">
                    <div class="advice-title2">建议</div>
                    <div class="advice-subtitle">
                        客户应该进一步有效实施规章制度，建立能源管理的奖罚机制、激励机制和约束机制，提高员工节能意识。
                    </div>
                </div>
            </div>
        </section>

        <!-- 能源计量器具诊断 -->
        <section class="sectionView clearfix section4">
            <div class="top clearfix">
                <div class="title fl">
                    能源计量器具诊断
                </div>

            </div>
            <div class="center">
                <el-table :data="tableData3" border style="width: 100%;" align="center">
                    <el-table-column label="能源类型" align="center">
                        <el-table-column prop="name" label="项目" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="进出用能单位" align="center">
                        <el-table-column prop="company1" label="要求配备率" align="center">
                        </el-table-column>
                        <el-table-column prop="company2" label="实际配备率" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="进出主要刺激用能单位" align="center">
                        <el-table-column prop="maincom1" label="要求配备率" align="center">
                        </el-table-column>
                        <el-table-column prop="maincom2" label="实际配备率" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="主要用能设备" align="center">
                        <el-table-column prop="dev1" label="要求配备率" align="center">
                        </el-table-column>
                        <el-table-column prop="dev2" label="实际配备率" align="center">
                        </el-table-column>
                    </el-table-column>

                </el-table>
                <div class="advice">
                    <div class="advice-title">总结</div>
                    <p>
                        依据《用能单位能源计量器具配备和管理通则》诊断：
                    </p>
                    <p>
                        <span class="blue">电力</span>计量配备<span class="blue">较为完善</span>；
                    </p>
                    <p>
                        <span class="blue">煤气、水</span>仅<span class="blue">进出用能单位较为完善，主要次级用能单位未安装</span>计量器具，<span
                            class="blue">主要用能设备未安装</span>计量器具。
                    </p>
                </div>
            </div>
        </section>

        <!-- 能源设备诊断 -->
        <section class="sectionView clearfix section5">
            <div class="top clearfix">
                <div class="title fl">
                    能源设备诊断
                </div>

            </div>
            <div class="center">
                <el-table :data="tableData4" border style="width: 100%;" align="center">
                    <el-table-column prop="device" label="设备" align="center">
                    </el-table-column>
                    <el-table-column prop="model" label="厂家/型号" align="center">
                    </el-table-column>
                    <el-table-column prop="number" label="设备数量" align="center">
                    </el-table-column>
                    <el-table-column prop="power" label="功率（kw/台）" align="center">
                    </el-table-column>
                    <el-table-column prop="situation" label="场所" align="center">
                    </el-table-column>
                </el-table>
                <div class="advice">
                    <div class="advice-title">总结</div>
                    <p>
                        该公司设备中，<span class="blue">存在</span>高耗能设备，建议更换，降低运营成本。更换为行业内推荐的同类设备，预计话费10万元，预计节省成本5万元，在2年内可以回本。
                    </p>
                </div>
                <div class="advice">
                    <div class="advice-title">总体改造方案总结</div>
                    <p>
                        总体来看，该企业的<span class="blue">能源使用情况、能源管理制度、能源计量器具、能源设备</span>四个方面，存在一定的问题，需要及时改进。
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import G6 from '@antv/g6';
    export default {
        data() {
            return {
                tableData1: [
                    {
                        area: '车间',
                        device: '地板胶线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '10000',
                    },
                    {
                        area: '车间',
                        device: '人造革线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '41',
                    },
                    {
                        area: '空压机房',
                        device: '空压机',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '50',
                    },
                    {
                        area: '车间',
                        device: '空压机',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '61',
                    },
                    {
                        area: '车间',
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                    {
                        area: '空压机房',
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                    {
                        area: '空压机房',
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                    {
                        area: '空压机房',
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                    {
                        area: '空压机房',
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                ],
                tableData2: [
                    {
                        type: '耗电量',
                        use: '1409.27kw·h',
                        conversion: '0.1229',
                        standard: '1731.99吨',
                        average: '1725.99吨',
                        contrast: '↑',
                    },
                    {
                        type: '用水量',
                        use: '1409.27kw·h',
                        conversion: '0.1229',
                        standard: '1731.99吨',
                        average: '1725.99吨',
                        contrast: '↓',
                    },
                    {
                        type: '煤气量',
                        use: '1409.27kw·h',
                        conversion: '0.1229',
                        standard: '1731.99吨',
                        average: '1725.99吨',
                        contrast: '↑',
                    },
                ],
                tableData3: [{
                    name: '电',
                    company1: '69%',
                    company2: '83%',
                    maincom1: '5%',
                    maincom2: '24%',
                    dev1: '73%',
                    dev2: '4%'

                },{
                    name: '煤',
                    company1: '69%',
                    company2: '83%',
                    maincom1: '5%',
                    maincom2: '24%',
                    dev1: '73%',
                    dev2: '4%'

                },{
                    name: '水',
                    company1: '69%',
                    company2: '83%',
                    maincom1: '5%',
                    maincom2: '24%',
                    dev1: '73%',
                    dev2: '4%'

                }],
                tableData4: [
                    {
                        
                        device: '地板胶线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '10000',
                        situation:'高耗能'
                    },
                    {
                       
                        device: '人造革线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '41',
                        situation:'高耗能'
                    },
                    {
                       
                        device: '空压机',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '50',
                        situation:'高耗能'
                    },
                    {
                        
                        device: '空压机',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '61',
                        situation:'高耗能'
                    },
                    {
                        
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                        situation:'高耗能'
                    },
                    {
                        
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                        situation:'节能'
                    },
                    {
                       
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                        situation:'高耗能'
                    },
                    {
                        
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                        situation:'高耗能'
                    },
                    {
                        
                        device: '薄膜线',
                        model: 'L-37-8.5',
                        number: '3',
                        power: '43',
                    },
                ],
            }
        },
        methods: {
            initRelationChart() {
                let relationData1 = {
                    name: '上海艾瑞斯电子有限公司',
                    level: '1',
                    children: [
                        {
                            name: '蓄电池',
                            level: '2',
                            children: [
                                {
                                    name: '温州某建材公司',
                                    level: '3',
                                },
                                {
                                    name: '温州某房地产公司',
                                    level: '3',
                                },
                            ],
                        },
                        {
                            name: '不间断电源',
                            level: '2',
                            children: [
                                {
                                    name: '某钢材贩卖点',
                                    level: '3',
                                },
                            ],
                        },
                        {
                            name: '不断电',
                            level: '2',
                            children: [
                                {
                                    level: '3',
                                    name: '铁路建设公司',
                                },
                            ],
                        },
                    ],
                };
                initRelationChart(relationData1, 'relation-chart1')
                initRelationChart(relationData1, 'relation-chart2')
                function initRelationChart(data, id) {
                    G6.registerNode(
                        'tree-node',
                        {
                            drawShape: function drawShape(cfg, group) {
                                const rect = group.addShape('rect', {
                                    attrs: {
                                        fill: cfg.level == '3' ? '#EAECF1' : cfg.level == '2' ? '#ecf5ff' : '#fff',
                                        stroke: '#0E459C',
                                        radius: [14],
                                        lineWidth: cfg.level == '2' ? 1 : 0,
                                    },
                                    name: 'rect-shape',
                                });
                                const content = cfg.name.replace(/(.{19})/g, '$1\n');
                                let text = group.addShape('text', {
                                    attrs: {
                                        text: content,
                                        x: 0,
                                        y: 0,
                                        textAlign: 'center',
                                        textBaseline: 'middle',
                                        fill: cfg.level != '3' ? '#0E459C' : '#666666',
                                        fontSize: cfg.level != '1' ? 6 : 8,
                                        fontWeight: cfg.level != '1' ? 400 : 600,
                                    },
                                    name: 'rect-shape',
                                });
                                console.log(text, content)
                                const bbox = text.getBBox();
                                rect.attr({
                                    x: bbox.minX - 10,
                                    y: bbox.minY - 10,
                                    width: bbox.width + 20,
                                    height: bbox.height + 20,
                                });
                                return rect;
                            },
                        },
                        'single-node'
                    );

                    const container = document.getElementById(id);
                    const width = container.scrollWidth;
                    const height = container.scrollHeight || 500;
                    const graph = new G6.TreeGraph({
                        container: id,
                        width,
                        height,
                        modes: {
                            default: ['collapse-expand', 'click-select', 'drag-node', 'drag-canvas', 'zoom-canvas'],
                            altSelect: [
                                {
                                    type: 'click-select',
                                    trigger: 'alt',
                                },
                                'drag-node',
                            ],
                        },
                        defaultNode: {
                            type: 'tree-node',
                            anchorPoints: [
                                [0, 0.5],
                                [1, 0.5],
                            ],
                        },
                        defaultEdge: {
                            type: 'cubic-horizontal',
                            style: {
                                stroke: '#A3B1BF',
                            },
                        },
                        layout: {
                            type: 'compactBox',
                            direction: 'LR',
                            getId: function getId(d) {
                                return d.id;
                            },
                            getHeight: function getHeight() {
                                return 16;
                            },
                            getWidth: function getWidth() {
                                return 16;
                            },
                            getVGap: function getVGap() {
                                return 20;
                            },
                            getHGap: function getHGap() {
                                return 80;
                            },
                        },
                    });
                    G6.Util.traverseTree(data, function (item) {
                        item.id = item.name;
                    });
                    graph.data(data);
                    graph.render();
                    graph.fitView();

                    graph.on('node:click', (evt) => {
                        const target = evt.target;
                        console.log(evt);
                        console.log(evt.item.get('model'));
                        if (evt.item.get('model').level == 3) {
                            this.show = true;
                            setTimeout(() => {
                                this.show = false;
                            }, 200);
                            // target.attrs.fill = '#0E459C';
                        }
                    });
                    window.onresize = function () {
                        if (!graph || graph.get('destroyed')) return;
                        if (!container || !container.scrollWidth || !container.scrollHeight) return;
                        graph.changeSize(container.scrollWidth, container.scrollHeight);
                    };
                }
            }
        },
        created() {

        },
        mounted() {
            this.initRelationChart()
        },

    }
</script>
<style scoped>
    .w100 {
        width: 100%;
    }

    .result {
        height: 76px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        line-height: 76px;
        background-color: #fff;
        padding: 0 20px;
    }

    .result .highlight {
        color: #E22132;
    }

    .sectionView {
        padding: 16px 20px;
        background-color: #fff;
        margin: 16px 0;
    }

    .sectionView .top {
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 16px;
        padding-bottom: 18px;
        z-index: 3;
        position: relative;
    }

    .sectionView .title {
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .sectionView .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 9px;
        left: 0;
    }

    .sectionView .p-title {
        font-size: 14px;
        color: #000;
        line-height: 20px;
        position: relative;
        padding-left: 6px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    .sectionView .p-title:before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 14px;
        background-color: #0E459C;
        position: absolute;
        top: 3px;
        left: 0;
    }

    .p-item {
        margin-bottom: 20px;
    }






    .section1 .info-box {
        width: calc((100% - 72px) / 4);
        padding: 15px;
        text-align: center;
        font-size: 14px;
        color: #666666;
        border-radius: 4px;
        margin-right: 16px;
    }

    .section1 .info-box:nth-child(-n+2) {
        background-image: linear-gradient(0deg, #EFF7FF 0%, #BDDEFF 100%);
    }

    .section1 .info-box:nth-child(2) {
        margin-right: 40px;
    }

    .section1 .info-box:nth-child(3) {
        border: 1px solid #255ADA;
    }

    .section1 .info-box:nth-child(4) {
        border: 1px solid #F5694D;
        margin-right: 0;
    }

    .section1 .info-box img {
        width: 48px;
        height: auto;
    }

    .section1 .info-box p {
        line-height: 24px;
    }

    .number {
        font-family: Helvetica;
        font-size: 24px;
        color: #000000;
    }

    .section1 .chart-container {
        background: #EFF7FF;
        padding: 16px;
    }

    .gauge {
        width: 16.6%;
        height: 110px;
    }

    .huantitle {
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        font-weight: 600;
    }

    .section4 table,
    .section4 table th {
        text-align: center;
    }

    .section4 table th {
        background: #F3F3F5 !important;
        font-weight: 600;
    }

    .green {
        color: #1AC643;
        font-weight: 600;
    }

    .blue {
        color: #255ADA;
        font-weight: 600;
    }

    .red {
        color: #E22132;
        font-weight: 600;
    }

    .advice {
        font-size: 14px;
        /* margin-bottom: 20px; */
        margin-top: 20px;
    }

    .advice-title {
        font-weight: 600;
        position: relative;
        display: inline-block;
    }

    .advice-title2 {
        font-weight: 600;
        position: relative;
        display: inline-block;
    }

    .advice-subtitle {
        font-weight: 600;
        color: #333;
    }

    .advice-title::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        height: 4px;
        width: 100%;
        opacity: 0.4;
        background: #255ADA;
    }

    .advice-title2::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        height: 4px;
        width: 100%;
        opacity: 0.4;
        background: #E22132;
    }

    .advice .advice-concent {
        text-indent: 22px;
    }

    .section5 {
        position: relative;
    }

    .card-list {
        margin: 20px 0;
    }

    .card-list .card-item {
        height: 105px;
        padding: 20px;
        width: calc((100% - 48px) / 4);
        margin-right: 16px;
        border-radius: 4px;
    }

    .card-list .card-item:nth-child(1) {
        background-image: linear-gradient(225deg, #6ED76E 0%, #2CC32C 100%);
    }

    .card-list .card-item:nth-child(2) {
        background-image: linear-gradient(225deg, #FEB75B 0%, #FD9237 100%);
    }

    .card-list .card-item:nth-child(3) {
        background-image: linear-gradient(225deg, #38A9FB 0%, #3D82F7 100%);
    }

    .card-list .card-item:nth-child(4) {
        background-image: linear-gradient(225deg, #6FDBF3 0%, #32C8EC 100%);
        margin-right: 0;
    }

    .card-list .card-item p {
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 28px;
    }

    .card-list .card-item p .number {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
    }

    .product-item {
        height: 140px;
        line-height: 140px;
        width: 25%;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        text-shadow: 0 2px 0 #255ADA;
    }

    .product-item:nth-child(1) {
        background: url(../../assets/img/IntelligentDiagnosis/znzd_zycp_img1.png) center center no-repeat;
    }

    .product-item:nth-child(2) {
        background: url(../../assets/img/IntelligentDiagnosis/znzd_zycp_img2.png) center center no-repeat;
    }

    .product-item:nth-child(3) {
        background: url(../../assets/img/IntelligentDiagnosis/znzd_zycp_img3.png) center center no-repeat;
    }

    .product-item:nth-child(4) {
        background: url(../../assets/img/IntelligentDiagnosis/znzd_zycp_img4.png) center center no-repeat;
    }

    .flow .flow-item {
        border-left: 1px dashed #255ADA;
        padding-left: 15px;
        padding-top: 20px;
        padding-bottom: 24px;
        position: relative;
    }

    .flow .flow-item:last-child {
        padding-bottom: 0;
    }

    .flow-item .flow-title {
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 0;
        position: absolute;
        top: 0;
        left: 15px;
    }

    .flow-item .flow-title::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #255ADA;
        position: absolute;
        top: -5px;
        left: -21px;
    }

    .relation-box {
        width: 45%;
        height: 370px;
    }

    >>>.el-table__header {
        border-collapse: collapse;
    }

    .section4>>>.el-table th {
        background: #3863A0;
        color: #fff;
    }
    .section5>>>.el-table th {
        background: #F3F3F5 ;
        color: #666;
    }
</style>